@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Roboto', 'sans-serif';
        box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
        margin: 0;
    }

    body {
        position: relative;
    }

    a {
        text-decoration: none;
    }

    p {
        margin: 0;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background: #EAEAEA;
    }

    ::-webkit-scrollbar-thumb {
        background: rgba(187, 189, 192, 0.5);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: rgba(187, 189, 192, 0.6);
    }

    ::-ms-reveal {
        display: none
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@layer components {
    .htk-border {
        @apply border-HTKBorder border-solid border-[1px]
    }
    /* Container Styles */
    .htk-container {
        @apply border-HTKBorder border-solid border-[1px] rounded-[3px]
    }
    .box-corner {
        @apply border-solid border-transparent content-[''] h-0 w-0
        pointer-events-none absolute border-t-[10px] border-b-[10px]
        border-l-[10px] border-r-[10px] -ml-[10px] border-b-HTKBorder bottom-full left-1/2
    }
    /* Button Styles */
    .login-button {
        @apply bg-HTKRed text-HTKWhite rounded-[3px] w-full text-lg
        flex items-center justify-center py-2 drop-shadow-sm
        disabled:bg-HTKRed/80 disabled:cursor-not-allowed
        hover:bg-HTKRed transition-all duration-150 ease-in-out
    }
    .new-button {
        @apply bg-HTKGreen/90 text-HTKWhite rounded-[3px]
        flex items-center justify-center pl-2 pr-1 py-1 drop-shadow-sm
        transition-all duration-200 ease-in-out 
        hover:bg-HTKGreen fill-HTKWhite outline-none
    }
    .main-detail-button {
        @apply bg-HTKRed/90 text-HTKWhite rounded-[3px]
        flex items-center justify-center px-2 py-1 drop-shadow-sm
        transition-all duration-200 ease-in-out 
        hover:bg-HTKRed outline-none
    }
    .deactivated-button {
        @apply bg-HTKGreen/90 text-HTKWhite rounded-[3px]
        flex items-center justify-center px-2 py-1 drop-shadow-sm
        transition-all duration-200 ease-in-out 
        hover:bg-HTKGreen outline-none
    }
    .sub-detail-button {
        @apply text-HTKRed flex items-center justify-center pl-2 pr-1 py-1
        transition-all duration-200 ease-in-out mx-2 outline-none
    }
    .presigned-url-button {
        @apply bg-HTKRed w-fit px-3 py-2 text-[1em] flex justify-center items-center
        rounded-[5px] text-HTKWhite cursor-pointer shadow-lg hover:scale-105
        transition-all duration-200 outline-none
    }
    .delete-goback-button {
        @apply text-HTKGreen flex items-center justify-center pl-2 pr-1 py-1
        transition-all duration-200 ease-in-out mx-2 outline-none
    }
    .modal-close-button {
        @apply bg-HTKRed/80 text-HTKWhite flex items-center justify-center px-4 py-2 rounded-[3px]
        drop-shadow-sm transition-all duration-200 ease-in-out hover:bg-HTKRed outline-none
    }
    .disabled-item-button {
        @apply bg-HTKGrey text-HTKWhite rounded-[3px] w-fit
        flex items-center justify-center py-1 px-2 drop-shadow-sm
        disabled:bg-HTKGrey/80 disabled:cursor-not-allowed outline-none
        hover:bg-HTKGrey transition-all duration-150 ease-in-out
    }
    .create-item-button {
        @apply bg-HTKGreen text-HTKWhite rounded-[3px] w-fit
        flex items-center justify-center py-1 px-2 drop-shadow-sm
        disabled:bg-HTKGreen/80 disabled:cursor-not-allowed outline-none
        hover:bg-HTKGreen transition-all duration-150 ease-in-out
    }
    .save-item-button {
        @apply text-HTKGreen rounded-[3px] w-fit
        flex items-center justify-center py-1 px-2 drop-shadow-sm
        disabled:cursor-not-allowed outline-none border-solid
        border-[2px] border-HTKGreen transition-all duration-150 ease-in-out
    }
    .cancel-item-button {
        @apply text-HTKRed flex items-center justify-center py-1 px-2 w-fit rounded-[3px]
        drop-shadow-sm transition-all duration-200 ease-in-out outline-none ml-3
    }
    .save-details {
        @apply text-HTKGreen rounded-[3px] w-fit
        flex items-center justify-center px-[26px] mr-3 drop-shadow-sm
        disabled:cursor-not-allowed outline-none border-solid
        border-[2px] border-HTKGreen transition-all duration-150 ease-in-out
    }
    .remove-superset {
        @apply text-HTKRed w-fit flex items-center justify-center
        outline-none transition-all duration-150 ease-in-out text-sm
        font-medium
    }
    .search-filter-button {
        @apply bg-HTKRed/90 text-HTKWhite rounded-[3px]
        flex items-center justify-center px-2 py-2 drop-shadow-sm
        transition-all duration-200 ease-in-out mx-2 text-sm
        hover:bg-HTKRed outline-none w-[9em]
    }
    .unrestrict-button {
        @apply text-HTKGreen rounded-[3px] w-fit
        flex items-center justify-center py-[2px] px-2 drop-shadow-sm
        disabled:cursor-not-allowed outline-none border-solid
        border-[2px] border-HTKGreen transition-all duration-150 ease-in-out
    }
}